jQuery(window).on('load',function(){

    location_function();
	validate_ogloszenia_form();
	fancy();
	noty_infos();
	

	jQuery('#ogloszenie_password').val('');
	jQuery('#ogloszenie_username').val('');
	jQuery('#ogloszenie_email').val('');

	

	//setTimeout(function(){
		//jQuery(".flex_preloader_wrapper").fadeOut( 500, function() {
		  //jQuery(".loader_wrapper").hide();
		  //jQuery(".loader_wrapper").css('z-index','-1');
		  //jQuery('html').css('overflow','auto');
	  //});
	  
  //}, 1500);


  scroll_top();

});



function scroll_top(){
	'use strict';
		
		var d = jQuery('body');
		if (navigator.userAgent.match(/Android|IEMobile|BlackBerry|iPhone|iPad|iPod|Opera Mini/i)) {
	
				$('.right_top_scroll').bind('touchstart', function (e) {
					jQuery('html,body').animate({ scrollTop: "0px" });
				});
				
		}else{
			
			jQuery('.right_top_scroll').on('click',function(){
				jQuery('html,body').animate({ scrollTop: "0px" });
			});
		
		}
	
	}
	

function noty_infos(){


	jQuery(".messages_notify #message-no_access_to_og" ).each( function(){

		generate_10('error', 'Wpisano niepoprawny kod lub minęło już 5 minut od pierwszego wpisania kodu.');

	});

	jQuery(".messages_notify #message-access_success" ).each( function(){

		generate_10('success', 'Kod poprawny.<br> Dane profilu zostały wyświetlone<br> Kod ważny jest 5 minut.');

	});



	jQuery(".messages_notify #message-ogloszenie_added" ).each( function(){
	    
		generate_30('success', 'Ogłoszenie zostało przekazane do sprawdzenia<br>Jeżeli wszystko będzie zgodne z zasadami niebawem pojawi się na stronie<br><br><b>Na adres email otrzymasz link celem potwierdzenia adresu email.</b>')

	});


	jQuery(".messages_notify #message-user_deleted" ).each( function(){

		generate_10('information', 'Ogłoszenie zostało pomyślnie usunięte wraz ze wszystkimi danymi');

	});

	jQuery(".messages_notify #message-user_not_deleted" ).each( function(){

		generate_10('error', 'Ogłoszenie nie zostało pomyślnie usunięte. Wprowadź poprawny email oraz hasło.');

	});


	jQuery(".messages_notify #message-email_removed" ).each( function(){

		generate_10('success', 'Adres email został trwale usunięty z naszych serwerów');

	});

	jQuery(".messages_notify #message-email_validated" ).each( function(){
	    
		generate_10('success', 'Adres email został potwierdzony.<br> Jeżeli wpis spełnia wymagania będzie niebawem udostępniony.<br> Dziękujemy za korzystanie z portalu <b>TeRandki.pl</b>')

	});

		

}


function fancy(){

	jQuery('.profile_box .photo').each( function(){

			
		jQuery(this).find('a.fancybox').fancybox({

			beforeLoad : function(){
				
				jQuery('body').removeClass('fancybox-active').removeClass('compensate-for-scrollbar');
				
			},
			beforeClose : function(){
				
			}

		});
	
	});


	jQuery('.single_profile_column_4 .first_image, .single_profile_column_4 .small_image').each( function(){

			
		jQuery(this).find('a.fancybox').fancybox({

			beforeLoad : function(){
				
				jQuery('body').removeClass('fancybox-active').removeClass('compensate-for-scrollbar');
				
			},
			beforeClose : function(){
				
			}

		});
	
	});

}



function validate_ogloszenia_form(){


	jQuery('.new_ogloszenie').on('submit', function(event){

		var check_ = false;
		var check_0 = false;
		var check_1 = false;
		var check_2 = false;
		var check_3 = false;
		var check_4 = false;
		var check_5 = false;
		var check_6 = false;
		var check_7 = false;
		var check_8 = false;
		var check_9 = false;
		var check_10 = false;
		var check_11 = false;
		var check_12 = false;
		var check_13 = false;
		var check_14 = false;


		var terms = false;
		var personal = false;

		if ( jQuery('#terms').is(':checked')){

			terms = true

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Zaakceptuj regulamin portalu. ')
			return false;

		}

		if ( jQuery('#personal_data').is(':checked')){

			personal = true

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Wyraź zgodę na przetwarzanie danych osobowych zgodnie z polityką prywatności portalu. ')
			return false;

		}
		
		

		// GENDER
		if ( jQuery('#ogloszenie_gender').val() >= 1 ){
			
			check_ = true;

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Uzupełnij: Płeć')
			return false;
		}

		// PASSWORD
		if ( jQuery('#ogloszenie_password').val().length >= 6 ){
			
			check_0 = true;

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Uzupełnij: Hasło ( min 6 znaków )')
			return false;
		}

		if ( jQuery('#ogloszenie_username').val().length >= 4 ){
			
			check_1 = true;

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Uzupełnij: Imię ( min 4 znaki )')
			return false;
		}

		if ( jQuery('#ogloszenie_email').val().length >= 8 ){
			
			check_2 = true;

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Uzupełnij: Adres email')
			return false;
		}

		// COUNTRY
		if ( jQuery('#ogloszenie_country_id').val() >= 1 ){
			
			check_3 = true;

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Uzupełnij: Kraj')
			return false;
		}

		// STATE
		if ( jQuery('#ogloszenie_state_id').val() >= 1 ){

			check_4 = true;

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Uzupełnij: Województwo')
			return false;
		}

		// CITY
		if ( jQuery('#ogloszenie_city_id').val() >= 1 ){

			check_5 = true;

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Uzupełnij: Miasto')
			return false;
		}

		// HEIGHT
		if ( jQuery('#ogloszenie_height').val() >= 1 ){

			check_6 = true;

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Uzupełnij: Wzrost')
			return false;
		}


		// FIGURE
		if ( jQuery('#ogloszenie_figure').val() >= 1 ){

			check_7 = true;

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Uzupełnij: Sylwetka')
			return false;
		}

		// KIDS
		if ( jQuery('#ogloszenie_kids').val() >= 1 ){

			check_8 = true;

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Uzupełnij: Dzieci')
			return false;
		}

		// STATUS
		if ( jQuery('#ogloszenie_status').val() >= 1 ){

			check_9 = true;

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Uzupełnij: Status Prawny')
			return false;
		}

		// EDUCATION
		if ( jQuery('#ogloszenie_education').val() >= 1 ){

			check_10 = true;

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Uzupełnij: Wykształcenie')
			return false;
		}

		// LANGUAGE
		if ( jQuery('#ogloszenie_my_lang').val() >= 1 ){

			check_11 = true;

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Uzupełnij: Język ojczysty')
			return false;
		}

		// CAR
		if ( jQuery('#ogloszenie_car').val() >= 1 ){

			check_12 = true;

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Uzupełnij: Przemieszczam się')
			return false;
		}

		// LIVE WITH
		if ( jQuery('#ogloszenie_live_with').val() >= 1 ){

			check_13 = true;

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Uzupełnij: Mieszkam')
			return false;
		}

		// LOOKING FOR
		if ( jQuery('#ogloszenie_looking_for').val() >= 1 ){

			check_14 = true;
			

		}else{

			generate_10('error', 'Wszystkie pola ( poza FaceBook ) należy uzupełnić<br> Uzupełnij: Kogo Tutaj Szukam')
			return false;
		}
		

	});

}



function generate_10(type,text) {
	var n = noty({
		text: text,
		type: type,
		timeout : 5000,
		dismissQueue: true,
		maxVisible: 5,
		layout: 'topRight',
		theme: 'defaultTheme'

	});
}



function generate_30(type,text) {
	var n = noty({
		text: text,
		type: type,
		timeout : 30000,
		dismissQueue: true,
		maxVisible: 5,
		layout: 'center',
		theme: 'defaultTheme'

	});
}




function location_function(){
	
	$("select#ogloszenie_state_id ,select#search_state_id ").change(function(){
		
	            var id_value_string = $(this).val();
				
	            if (id_value_string == "") { 
	                // if the id is empty remove all the sub_selection options from being selectable and do not do any ajax
	                $("select#search_city_id option, select#ogloszenie_city_id option").remove();
	                var row = "<option value=\"" + "" + "\">" + "" + "</option>";
	                $(row).appendTo("select#search_city_id, select#ogloszenie_city_id");
					
	            }else {
					
	                // Send the request and update sub category dropdown 
	                $.ajax({

	                    dataType: "json",
	                    cache: false,
	                    url: '/cities/for_sectionid/' + id_value_string,
	                    timeout: 2000,
	                    error: function(XMLHttpRequest, errorTextStatus, error){
	                        
	                    },
	                    success: function(data){           
		         			
	                        // Clear all options from sub category select 
	                        $("select#search_city_id option, select#ogloszenie_city_id option").remove();
	                        //put in a empty default line
	                        var row = "<option value=\"" + "" + "\">" + "" + "</option>";
	                        $(row).appendTo("select#search_city_id ,select#ogloszenie_city_id");   
							//$('select.chosen-select').trigger("chosen:updated");         
							       
	                        // Fill sub category select 
							
	                        $.each(data, function(i, j){
								
	                            row = "<option value=\"" + j.id + "\">" + j.name + "</option>";  
	 							//alert(row);
	                            $(row).appendTo("select#ogloszenie_city_id, select#search_city_id");   
								//$('select#profile_city_id, select#search_city_id').selectBox('refresh');
	                  			//$('select.chosen-select').trigger("chosen:updated");
								
	                        });
	                                   
	                     }
	                });
	            };
	});
	
	
	
}